var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container Staffing" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: { click: _vm.modification },
                          },
                          [_vm._v("批量修改")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              clearable: "",
                              placeholder: "请搜索人员名称",
                              size: "small",
                            },
                            model: {
                              value: _vm.search_realname,
                              callback: function ($$v) {
                                _vm.search_realname = $$v
                              },
                              expression: "search_realname",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.ClassSearchInput },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticStyle: { color: "#1890FF" } },
                        [
                          _c("span", [
                            _c("i", {
                              staticClass: "iconfont icon-bumen-moren",
                              staticStyle: { "font-size": "16px" },
                            }),
                            _vm._v(" 本部门共有"),
                            _c(
                              "b",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v(_vm._s(_vm.total.user))]
                            ),
                            _vm._v("人，可配置\n                        "),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.total.list, function (item, index) {
                            return _c("span", { key: index }, [
                              _c("span", [
                                _vm._v(
                                  "\n                                等级" +
                                    _vm._s(item.title)
                                ),
                                _c(
                                  "b",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "16px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.num))]
                                ),
                                index == _vm.total.list.length - 1
                                  ? _c("span", [_vm._v("人")])
                                  : _c("span", [_vm._v("人，")]),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "15px" },
                        attrs: { span: 24 },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { color: "#fbad26" } },
                          [
                            _c("span", [
                              _c("i", {
                                staticClass: "iconfont icon-config",
                                staticStyle: {
                                  "font-size": "17px",
                                  "margin-left": "1px",
                                },
                              }),
                              _vm._v(" 当前还有"),
                              _c(
                                "b",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "18px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.wait.user))]
                              ),
                              _vm._v("人待配置，"),
                            ]),
                            _vm._v(" "),
                            _c("span"),
                            _vm._v(" "),
                            _c("span", [_vm._v("其中可配置")]),
                            _vm._v(" "),
                            _vm._l(_vm.wait.list, function (item, index) {
                              return _c("span", { key: index }, [
                                _c("span", [
                                  _vm._v(
                                    "\n                                等级" +
                                      _vm._s(item.title)
                                  ),
                                  _c(
                                    "b",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "font-size": "18px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.num))]
                                  ),
                                  index == _vm.wait.list.length - 1
                                    ? _c("span", [_vm._v("人")])
                                    : _c("span", [_vm._v("人，")]),
                                ]),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.labelpeersshow,
                                expression: "labelpeersshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("人员等级：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: { size: "small", placeholder: "请选择" },
                                model: {
                                  value: _vm.labelpeers,
                                  callback: function ($$v) {
                                    _vm.labelpeers = $$v
                                  },
                                  expression: "labelpeers",
                                },
                              },
                              _vm._l(_vm.labelpeersEs, function (item) {
                                return _c("el-option", {
                                  key: item.level_config_id,
                                  attrs: {
                                    label: item.title,
                                    value: item.level_config_id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.batchClick },
                              },
                              [_vm._v("确定")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("人员等级")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              { staticStyle: { width: "100%", "margin-bottom": "20px" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    attrs: {
                      data: _vm.tableData,
                      "row-key": "id",
                      border: "",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "structure_name", label: "部门名称" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.structure_name != ""
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.structure_name)),
                                    ])
                                  : _c(
                                      "el-checkbox-group",
                                      {
                                        staticStyle: { "margin-top": "-24px" },
                                        model: {
                                          value: _vm.checkList,
                                          callback: function ($$v) {
                                            _vm.checkList = $$v
                                          },
                                          expression: "checkList",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: scope.row.id } },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.structure_name)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3570836782
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "realname",
                        align: "center",
                        label: "人员姓名",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "title",
                        align: "center",
                        label: "人员等级",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "everyday_max",
                        align: "center",
                        label: "每日分配上限",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "not_visit_max",
                        align: "center",
                        label: "未回访上限",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "380", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == 0
                                  ? _c("span")
                                  : scope.row.type == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "red" },
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.configureClick(
                                              1,
                                              scope.row.realname,
                                              scope.row.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("配置")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.configureClick(
                                              2,
                                              scope.row.realname,
                                              scope.row.id,
                                              scope.row.level_config_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3809019673
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticClass: "big-dialogs",
                    attrs: { title: _vm.grade, visible: _vm.configure },
                    on: {
                      "update:visible": function ($event) {
                        _vm.configure = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "400px", "margin-left": "50px" },
                        attrs: {
                          "label-position": "right",
                          "label-width": "130px",
                        },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "人员姓名:" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.searchRealname))]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "等级名称:" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: { size: "small", placeholder: "请选择" },
                                model: {
                                  value: _vm.labelpeer,
                                  callback: function ($$v) {
                                    _vm.labelpeer = $$v
                                  },
                                  expression: "labelpeer",
                                },
                              },
                              _vm._l(_vm.labelpeersEs, function (item) {
                                return _c("el-option", {
                                  key: item.level_config_id,
                                  attrs: {
                                    label: item.title,
                                    value: item.level_config_id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "#999",
                                  "margin-top": "0px",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-tixing",
                                }),
                                _vm._v(" 配置修改后最晚第二天生效"),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { "padding-top": "50px" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", disabled: _vm.prohibit },
                            on: { click: _vm.toConfigure },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }