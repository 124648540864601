import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import { UserLevelConfig, LevelConfig, configUpdate } from '@/api/updateUserInfo/Msg';
import NProgress from 'nprogress'; // progress bar
import Head from '@/components/head/index';
import 'nprogress/nprogress.css'; // progress bar style
export default {
  name: 'Staffing',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '人员级别配置',
      value1: '',
      value: '',
      checkList: [],
      total: {
        user: '',
        list: []
      },
      wait: {
        user: '',
        list: []
      },
      searchRealname: '',
      urlPath: this.getUrlPath(),
      // iframe src 路径，
      dialogFormVisible: false,
      rules: {
        name: [{
          required: true,
          message: '请输入必填项',
          trigger: 'change'
        }],
        pid: [{
          required: true,
          message: '请输入必填项',
          trigger: 'blur'
        }]
      },
      temp: {
        pid: '',
        // 默认是0,为顶级分类.如不选择则为顶级分类
        sort: 1,
        //菜单排序
        title: '',
        // 栏目名称
        path: '',
        // 定义
        component: '',
        // 注册路径
        api: '',
        is_menu: 1 //
      },
      newly: '',
      search_realname: '',
      userID: '',
      tableData: [],
      grade: '人员等级',
      configure: false,
      labelpeersshow: false,
      labelpeer: '',
      labelpeers: '',
      msg: '配置成功',
      num: '',
      listLoading: false,
      labelpeersEs: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.getList();
      LevelConfig().then(function (res) {
        _this.labelpeersEs = res.data;
      });
    } else {}
  },
  components: {
    Head: Head
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      //栏目列表
      this.listLoading = true;
      UserLevelConfig({
        search_realname: this.search_realname
      }).then(function (respomse) {
        _this2.tableData = respomse.data.list;
        _this2.total = {
          user: respomse.data.total.user,
          list: respomse.data.total.list
        };
        _this2.wait = {
          user: respomse.data.wait.user,
          list: respomse.data.wait.list
        };
        _this2.listLoading = false;
      });
    },
    ClassSearchInput: function ClassSearchInput() {
      this.getList();
    },
    modification: function modification() {
      if (this.checkList.length > 0) {
        this.labelpeersshow = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请选择人员'
        });
      }
    },
    Deletesshow: function Deletesshow(item) {
      if (item === '人员等级') {
        this.labelpeersshow = false;
        this.labelpeers = '';
        this.checkList = [];
      }
    },
    configureClick: function configureClick(num, name, userid, share) {
      if (num == 1) {
        this.msg = '配置成功';
      } else {
        this.msg = '编辑成功';
        this.labelpeer = share;
      }
      this.num = num;
      this.searchRealname = name;
      this.userID = userid;
      this.configure = true;
    },
    toConfigure: function toConfigure() {
      var _this3 = this;
      configUpdate({
        user_ids: String(this.userID),
        level_config_id: Number(this.labelpeer)
      }).then(function (res) {
        _this3.$notify({
          title: '成功',
          message: _this3.msg,
          type: 'success'
        });
        _this3.getList();
        _this3.labelpeer = '';
        _this3.configure = false;
      });
    },
    batchClick: function batchClick() {
      var _this4 = this;
      this.msg = '批量修改成功';
      if (this.labelpeers != '') {
        configUpdate({
          user_ids: String(this.checkList),
          level_config_id: Number(this.labelpeers)
        }).then(function (res) {
          _this4.$notify({
            title: '成功',
            message: _this4.msg,
            type: 'success'
          });
          _this4.getList();
          _this4.checkList = [];
          _this4.labelpeers = '';
          _this4.configure = false;
        });
      } else {
        this.$notify({
          title: '警告',
          message: '请选择人员等级',
          type: 'warning'
        });
      }
    },
    getUrlPath: function getUrlPath() {
      // 获取 iframe src 路径
      var url = window.location.href;
      url = url.replace('/myiframe', '');
      return url;
    }
  }
};